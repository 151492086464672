<template>
    <div id="totop" class="no-header-template pattern-bg">
        <div class="container-espe fullwidth filters-bar title-bar endborder white-bg">
            <h1>Chi supportiamo</h1>
        </div>

        <div class="container-espe fullwidth filters-bar endborder white-bg">
            <filters-bar 
                type="projects"
                :filtersdata="this.filtersData" 
                :activefilters="this.activeFilters" 
                :title="this.pageData.attributes.title"
                @search="handleSearch" 
                @combine-filter="handleFiltering"
                @bash-combine="handleBashFiltering">
            </filters-bar>
        </div>

        <div class="container-espe pattern-bg">
            <div class="projects" >
                <project-tile v-for="(project, index) in this.filteredProjects" :key="'project'+index" 
                    :donation="false"
                    :projectdata="project" 
                    :filtersdata="filtersData"
                    :routes="routesData">
                </project-tile>
            </div>
        </div>
    </div>
</template>

<script>
import FiltersBar from '../components/FiltersBar.vue'
import ProjectTile from '../components/ProjectTile.vue'
import { fetchNodes, fetchTaxonomy, fetchRoutes } from '../libs/drupalClient'

export default {
    name: 'chi-supportiamo',
    components: {
        ProjectTile,
        FiltersBar
    },
    data: () => {
        return {    
            pageData: { 
                attributes: {
                    title: "Chi supportiamo",
                    body: {
                        summary: ""
                    }
                }
            },
            currentPageId: "",
            filtersData: [],
            projectData: [],
            routesData: [],
            activeFilters: [],
            nameSearch: ""
        }
    },
    computed: {
        lang() {
            return this.$store.state.currentLang
        },
        defaultLang() {
            return this.$store.state.defaultLang
        },
        filteredProjects() {
            var finalPJ = []

            if(this.activeFilters.length==0)
                finalPJ = this.projectData
            else {
                this.projectData.forEach(pj => {
                    if(this.activeFilters.includes(pj.field_project_settore.attributes.drupal_internal__tid)) 
                        finalPJ.push(pj)
                })
            }

            finalPJ = finalPJ.filter(fe => {
                var amongSettori = fe.field_project_settore.attributes.name.toLowerCase().includes(this.nameSearch.toLowerCase())
                
                if(fe.attributes.title.toLowerCase().includes(this.nameSearch.toLowerCase())
                    || amongSettori)
                    return true
                else return false
            })

            return finalPJ
        }
    },
    methods: {
         handleFiltering(value) {
            if(this.activeFilters.includes(value))
                this.activeFilters.splice(this.activeFilters.indexOf(value), 1)
            else
                this.activeFilters.push(value)
        },
        handleBashFiltering(value) {
            this.activeFilters = []
            value.forEach(v => {
                this.activeFilters.push(v)
            })

            window.scrollTo({
                top: document.getElementById('totop').getBoundingClientRect().top,
                behavior: 'smooth'
            })
        },
        handleSearch(value) {
            this.nameSearch = value 
        }
    },
    async mounted() {
        await fetchRoutes().then(r => this.routesData = r.routes)

        await fetchNodes('project', { include: ['field_project_foto', 'field_project_settore']}, this.lang)
        .then(res => {
            this.projectData = res
        })

        fetchTaxonomy('settore', {}, this.lang).then(res => {
            this.filtersData = res
        })
    }
}
</script>

<style lang="scss">
</style>